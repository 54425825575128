* {
  box-sizing: border-box;
}

/* Works on Firefox */
* {
  scrollbar-width: 10px;
  scrollbar-color: #e1e1e1;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-thumb {
  background-color: #e1e1e1;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  cursor: pointer;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

::highlight(search-results) {
  background-color: rgb(233, 223, 33) !important;
  /* color: white; */
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
  max-height: 48px;
  /* keep the same height */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiAutocomplete-tag {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.MuiAutocomplete-listbox {
  max-height: 48px;
  /* keep the same height */
  overflow-y: auto;
}

.MuiAutocomplete-popupIndicatorOpen {
  transform: rotate(180deg);
}

/* Split Panel Styles */

.Pane {
  overflow: hidden;
}

.Pane2 {
  min-height: 0;
}

.Resizer {
  position: relative;
  flex: 0 0 auto;
  background: #0003;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:after {
  content: "…";
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 10px;
  background: #f9f9f9;
  border: 1px solid #0002;
  border-radius: 20px;
  color: #0006;
  text-align: center;
  line-height: 4px;
  font-size: 12px;
  z-index: 12;

  transform: translate(-50%, -50%);
}

.Resizer:hover:after {
  background: #fff;
}

.Resizer:hover {
  -webkit-transition: border-color 200ms ease;
  transition: border-color 200ms ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(102, 162, 253, 0);
  border-bottom: 5px solid rgba(102, 162, 253, 0);
  cursor: n-resize;
  width: 100%;
}

.Resizer.horizontal:hover,
.Resizer.horizontal:active {
  background-color: rgba(102, 162, 253, 1);
  border-top: 5px solid rgba(102, 162, 253, 0.4);
  border-bottom: 5px solid rgba(102, 162, 253, 0.4);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(102, 162, 253, 0);
  border-right: 5px solid rgba(102, 162, 253, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover,
.Resizer.vertical:active {
  background-color: rgba(102, 162, 253, 1);
  border-left: 5px solid rgba(102, 162, 253, 0.4);
  border-right: 5px solid rgba(102, 162, 253, 0.4);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

/*----------------------------wrapper of dragable------------------------------------*/

.dwrapper {
  --dnd-status-color: 180 180 255;

  transition: background-color 150ms, border-color 150ms;

  padding: 0.4rem;
  border: 2px solid rgb(var(--dnd-status-color) / 0.7);
  background: rgb(var(--dnd-status-color) / 0.2);
  border-radius: 0.4rem;

  display: flex;
  gap: 0.2rem;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 32px;
}

.dwrapper:hover {
  --dnd-status-color: 95 95 200;
}

@keyframes dropped-highlight {
  from {
    border-color: rgb(39, 39, 252);
    background: rgb(39 39 252 / 0.3);
  }

  to {
    border-color: rgb(180 180 255 / 0.7);
    background: rgb(180 180 255 / 0.2);
  }
}

.dwrapper:global(.dropped) {
  animation: dropped-highlight 1.5s 1;
}

/* Context menu css */

nav.react-contextmenu {
  position: fixed;
  width: 200px;
  left: 0;
  top: calc(100% + 10px);
  border-radius: 4px;
  background-color: #fff;
  padding: 10px 0;
  z-index: 99;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.react-contextmenu .react-contextmenu-item:hover {
  background: #cccccc;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: #f1f1f1;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  cursor: pointer;
  user-select: none;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  font-weight: 500;
  display: flex;
  align-items: center;
  outline: none;
}

.react-contextmenu-item span {
  margin-left: 10px;
}

.react-contextmenu-item svg {
  font-size: 14px;
}

.react-contextmenu-item .copy {
  color: skyblue;
}

.react-contextmenu-item .delete {
  color: red;
}

.react-contextmenu-item .send {
  color: blue;
}

.react-contextmenu-item .share {
  color: green;
}

.react-contextmenu-item .watchlist {
  color: rebeccapurple;
}

/* flexlaout container */

.my__flex__layout__container {
  flex-grow: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: relative;
  border: 1px solid gray;
}

.highlight-search-input {
  background-color: yellow;
  font-weight: bold;
  color: black;
}

.MuiTablePagination-displayedRows {
  margin: 0;
}

.MuiTablePagination-root > .MuiToolbar-root > .MuiInputBase-root {
  margin-left: 4px;
  margin-right: 8px;
  padding: 2px 12px;
}

.MuiTablePagination-root > .MuiToolbar-root > .MuiTablePagination-actions {
  margin-left: 12px;
}

.MuiTablePagination-root
  > .MuiToolbar-root
  > .MuiTablePagination-actions
  > .MuiButtonBase-root {
  padding: 0;
}

.MuiDataGrid-footerContainer {
  padding: 0 !important;
}

@keyframes MyDiv {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    left: 100px;
    top: 0px;
  }
}

.batch_due_rep_fee_group_even {
  background-color: #e9ecef;
}

.batch_due_rep_fee_group_even
  > .MuiDataGrid-columnHeaderDraggableContainer
  > .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  align-items: center;
}

.batch_due_rep_fee_group_odd {
  background-color: #344767;
}

.batch_due_rep_fee_group_odd
  > .MuiDataGrid-columnHeaderDraggableContainer
  > .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

.one-checkbox {
  --s: 20px;
  --thumb-color: #ffffff;
  --track-color: rgba(58, 65, 111, 0.1);
  --border-color: rgb(233, 236, 239);
  --track-checked-color: #3a416f;

  height: calc(var(--s) + var(--s) / 5);
  width: auto;
  aspect-ratio: 2.25;
  border-radius: var(--s);
  margin: calc(var(--s) / 2);
  display: grid;
  cursor: pointer;
  box-sizing: content-box;
  overflow: hidden;
  transition: 0.3s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: var(--track-color);
  border: 0.0625rem solid var(--border-color);
  position: relative;
}

.one-checkbox::before {
  content: "";
  position: absolute;
  height: var(--s);
  aspect-ratio: 1;
  top: 50%;
  left: calc(var(--s) / 10);
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: var(--thumb-color);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.one-checkbox:checked::before {
  left: calc(100% - var(--s) - calc(var(--s) / 10));
}

.one-checkbox:checked {
  background-color: var(--track-checked-color);
}
/* .css-61io1e {
  margin: 0 !important;
} */
